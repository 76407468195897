import { IconButton, Typography, styled } from "@mui/material"
import theme from "../../theme"

export const OTPModalContent = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 408px;
  padding: 24px;
  align-items: center;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    padding: 20px;
  }
`
export const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`
export const OTPModalTitle = styled(Typography)`
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
`
export const OTPModalSubtite = styled(Typography)`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: ${theme.palette.neutrals[1]};
  span {
    font-weight: 600;
  }
  .resend {
    color: ${theme.palette.neutrals[1]};
    text-decoration: underline;
    cursor: pointer;
  }
`

export const InputWrapper = styled("div")`
  display: flex;
  gap: 12px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    gap: 8px;
  }
`

export const OTPModalInput = styled("input")`
  flex: 1;
  max-width: 80px;
  height: 80px;
  padding: 10px 8px;
  border-radius: 8px;
  border: 2px solid ${theme.palette.neutrals[4]};
  box-shadow: 0px 1px 2px 0px ${theme.palette.neutrals[4]};
  font-size: 48px;
  font-weight: 500;
  text-align: center;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    max-width: 64px;
    height: 64px;
    font-size: 36px;
  }
`

export const ButtonWrapper = styled("div")`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  gap: 12px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    margin-top: 24px;
    flex-direction: column-reverse;
  }
`
