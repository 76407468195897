import { Dialog, Stack } from "@mui/material"
import {
  OTPModalContent,
  OTPModalInput,
  OTPModalTitle,
  OTPModalSubtite,
  CloseIconButton,
  InputWrapper,
  ButtonWrapper,
} from "./styles"
import OTPMobileIcon from "../../assets/OTPMobileIcon.svg"
import { useEffect, useRef, useState } from "react"
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query"
import { GetOTPParams, UserLoginParams } from "../../apis/userApi"
import { Button } from "../Button"
import CloseIcon from "@mui/icons-material/Close"
import { User } from "../../models/users.model"

type OTPModalProps = {
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  mobileNumber: string
  onConfirm: MutationTrigger<
    MutationDefinition<
      UserLoginParams,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        object,
        FetchBaseQueryMeta
      >,
      "centers",
      User,
      "/api"
    >
  >
  resendOTP: MutationTrigger<
    MutationDefinition<
      GetOTPParams,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        object,
        FetchBaseQueryMeta
      >,
      "centers",
      any,
      "/api"
    >
  >
}

const OTPModal = ({
  open,
  onClose,
  onConfirm,
  mobileNumber,
  resendOTP,
}: OTPModalProps) => {
  const [inputValues, setInputValues] = useState<string[]>(["", "", "", ""])
  const inputRefs = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    return () => {
      // Reset state on unmount
      setInputValues(["", "", "", ""])
    }
  }, [])

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRefs.current[0]])

  const handleInputChange = (index: number, value: string, e: any) => {
    if (value.length > 1) {
      e.clipboardData = {
        getData: () => value.trim(),
      }
      handlePaste(e)
    } else {
      const sanitizedValue = value.replace(/\D/g, "").substr(0, 1)
      setInputValues(() => {
        const newValues = [...inputValues]
        newValues[index] = sanitizedValue
        return newValues
      })

      if (sanitizedValue !== "" && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus()
      }
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pastedData = event.clipboardData.getData("text").replace(/\D/g, "")
    const newValues = [...inputValues]

    for (let i = 0; i < pastedData.length; i++) {
      if (i < inputRefs.current.length) {
        newValues[i] = pastedData[i]
      }
    }
    setInputValues(newValues)
  }

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus()
      } else {
        event.preventDefault()
        handleConfirm()
      }
    } else if (
      (event.key === "Backspace" || event.key === "Delete") &&
      index > 0
    ) {
      event.preventDefault()
      const newValues = [...inputValues]
      newValues[index] = ""
      setInputValues(newValues)
      inputRefs.current[index - 1].focus()
    }
  }

  const handleConfirm = () => {
    if (inputValues.every((value) => value !== "")) {
      onConfirm({ mobileNumber, OTP: inputValues.join("") })
      onClose(false)
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="otp-dialog-title"
      aria-describedby="otp-dialog-description"
      onClose={onClose}
    >
      <CloseIconButton onClick={() => onClose(false)}>
        <CloseIcon />
      </CloseIconButton>
      <OTPModalContent>
        <img src={OTPMobileIcon} height="48px" alt="" />
        <OTPModalTitle>
          Please check the OTP received on your mobile
        </OTPModalTitle>
        <OTPModalSubtite>
          We've sent the code to <span>{mobileNumber}</span>
        </OTPModalSubtite>
        <Stack direction="column" mt="20px">
          <InputWrapper>
            {inputValues.map((value, index) => (
              <OTPModalInput
                key={index}
                type="text"
                inputMode="numeric"
                value={value}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    e.target.value,
                    e as unknown as React.ClipboardEvent<HTMLInputElement>,
                  )
                }
                onPaste={(e) => handlePaste(e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(input) => {
                  if (input) {
                    inputRefs.current[index] = input
                  }
                }}
              />
            ))}
          </InputWrapper>
          <OTPModalSubtite sx={{ textAlign: "left" }}>
            Didn’t receive a code?{" "}
            <span
              className="resend"
              onClick={() => resendOTP({ mobileNumber })}
            >
              Click to resend
            </span>
          </OTPModalSubtite>
        </Stack>
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </ButtonWrapper>
      </OTPModalContent>
    </Dialog>
  )
}

export default OTPModal
