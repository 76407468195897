import { IconButton, Typography, styled } from "@mui/material"
import theme from "../../../theme"

export const StepperCard = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  margin-top: 24px;
  background: ${theme.palette.background.paper};
  border-radius: 10px;
  border: 1px solid ${theme.palette.neutrals[4]};
  box-shadow: 0px 4px 4px -6px ${theme.palette.neutrals[4]};

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    border-radius: 12px;
    padding: 16px;
    gap: 16px;
  }
`
export const StepperCardHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  height: 48px;
`
export const StepperCardBody = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    gap: 12px;
  }
`

export const StepperCardFooter = styled("div")`
  display: flex;
  justify-content: space-between;
`

export const MobileNumberLabel = styled(Typography)`
  font-size: 16px;
  font-weight: 500;

  span {
    text-decoration: underline;
    font-weight: 700;
  }

  @media (max-width: ${theme.breakpoints.values["sm"]}px) {
    font-weight: 600;
  }
`

export const VerifiedUserName = styled("div")`
  display: flex;
  height: 48px;
  border-radius: 8px;
  padding: 12px;
  background: ${theme.palette.success.light};

  color: ${theme.palette.success.main};
  font-size: 14px;
  font-weight: 500;
`
export const DateButton = styled("div")`
  height: 44px;
  border-radius: 4px;
  background: ${theme.palette.secondary.main};
  padding: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  position: relative;

  span {
    font-size: 12px;
    font-weight: 500;
  }
  img {
    position: absolute;
    left: 0;
    top: -1px;
    display: none;
  }

  &.selected {
    background: ${theme.palette.primary.light};
    border: 1px solid ${theme.palette.primary.dark};
    img {
      display: block;
    }
  }
`

export const NumberOfPassesLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`

export const PassesButtonWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 4px;
  height: 44px;
  border-radius: 7px;
  border: 0.5px solid ${theme.palette.neutrals[4]};
  background: ${theme.palette.secondary.main};
`

export const StyledIconButton = styled(IconButton)`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${theme.palette.primary.dark};
  background: ${theme.palette.primary.light};
`

export const NumberOfPassesText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`

export const InfoDiv = styled("div")`
  border-radius: 3px;
  padding: 6px 8px;
  background: ${theme.palette.secondary.main};
  border: 0.5px solid ${theme.palette.neutrals[4]};
  font-size: 12px;
  font-weight: 400;
`

export const AddMemberText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  span {
    font-size: 12px;
    font-weight: 500;
  }
`

export const AddMemberSubText = styled(Typography)`
  font-size: 10px;
  font-weight: 400;
  color: ${theme.palette.neutrals[1]};
`

export const ErrorText = styled("div")`
  width: max-content;
  margin-inline: auto;
  border-radius: 3px;
  padding: 4px;
  background: ${theme.palette.error.light};
  color: ${theme.palette.error.main};
  font-size: 10px;
  font-weight: 400;
`

export const UnderlineText = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
  color: ${theme.palette.primary.dark};
  cursor: pointer;

  &.clicked {
    color: ${theme.palette.text.primary};
  }
`
export const DottedDivider = styled("div")`
  margin-top: 14px;
  border: 1px dotted ${theme.palette.neutrals[4]};
`

export const HistoryItem = styled("div")`
  display: flex;
  justify-content: space-between;
  height: 44px;
  padding: 10px;
  border-radius: 8px;
  border: 0.5px solid ${theme.palette.neutrals[4]};
  background: ${theme.palette.secondary.main};
  font-size: 16px;
  font-weight: 400;
  color: ${theme.palette.neutrals[2]};
`

export const BookingText = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
`

export const BookingDetailsRow = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const BookingDetailsRowItem = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: ${theme.palette.neutrals[1]};

  svg {
    height: 14px;
  }
`

export const VerticalDivider = styled("div")`
  width: 1px;
  height: 20px;
  background: ${theme.palette.neutrals[2]};
`

export const PriceBeakupText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
`

export const PriceBeakupRow = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const PriceBeakupSubText = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  color: ${theme.palette.neutrals[1]};
`

export const TotalRowText = styled(Typography)`
  font-size: 16px;
  font-weight: 500;
  span {
    font-size: 14px;
    font-weight: 400;
    color: ${theme.palette.neutrals[1]};
  }
`

export const ValidTextDiv = styled("div")`
  width: max-content;
  margin-inline: auto;
  border-radius: 3px;
  padding: 4px;
  background: ${theme.palette.success.light};
  color: ${theme.palette.success.main};
  font-size: 10px;
  font-weight: 400;
`
