import { useEffect, useState } from "react"
import {
  StepperCardFooter,
  StepperCardHeader,
  StepperCardBody,
  VerifiedUserName,
  AddMemberText,
  UnderlineText,
  HistoryItem,
  DottedDivider,
  ErrorText,
  PriceBeakupSubText,
  AddMemberSubText,
} from "./styles"
import { IconButton, Stack, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import VerifiedUserIcon from "../../../assets/VerifiedUserIcon.svg"
import AccountIcon from "../../../assets/AccountIcon.svg"
import { Button } from "../../../components/Button"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import {
  clearMobileNumber,
  selectBooking,
  setMobileNumbers,
} from "../../../slices/bookingSlice"
import RestoreIcon from "@mui/icons-material/Restore"
import { useGetUserContactsByIdQuery } from "../../../apis/userApi"
import PhoneInput from "../../../components/PhoneInput/PhoneInput"
import { useSearchParams } from "react-router-dom"
import { URLParams } from "../../../constants/enums"
import { selectUser } from "../../../slices/userSlice"

export const AddMembers = () => {
  const dispatch = useAppDispatch()
  const { userData } = useAppSelector(selectUser)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const { numberOfPasses, mobileNumber, mobileNumbers } =
    useAppSelector(selectBooking)
  const [showLess, setShowLess] = useState(false)
  const [errors, setErrors] = useState<boolean[]>(
    Array(numberOfPasses).fill(false),
  )
  const [duplicateNumberError, setDuplicateNumberError] = useState(false)

  const { data: userContacts } = useGetUserContactsByIdQuery({
    userId: userData?.userId || "",
  })

  // Initialize the first input with the selected mobileNumber
  useEffect(() => {
    if (mobileNumber) {
      const updatedMobileNumbers = [...mobileNumbers]
      updatedMobileNumbers[0] = mobileNumber
      dispatch(setMobileNumbers(updatedMobileNumbers))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mobileNumber])

  const handleMobileNumberChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      if (value.length <= 10) {
        const newMobileNumbers = [...mobileNumbers]
        newMobileNumbers[index] = value
        dispatch(setMobileNumbers(newMobileNumbers))
      }
      if (value.length === 10) {
        const newErrors = [...errors]
        newErrors[index] = false
        setErrors(newErrors)
      }
    }

  const handleClearMobileNumber = (index: number) => () => {
    dispatch(clearMobileNumber({ index }))
  }

  const renderMobileNumberInputs = () => {
    return Array.from({ length: numberOfPasses }, (_, index) => (
      <Stack direction="row" alignItems="baseline" gap={2} width="100%">
        <PriceBeakupSubText style={{ minWidth: "80px" }}>{`Member ${
          index + 1
        }`}</PriceBeakupSubText>
        <PhoneInput
          key={index}
          value={mobileNumbers[index] || ""}
          onChangeHandler={handleMobileNumberChange(index)}
          handleClearMobileNumber={handleClearMobileNumber(index)}
          error={errors[index]}
          handlePressEnter={handleConfirmButtonClick}
        />
      </Stack>
    ))
  }

  const handleAddMobileNumber = (mobile: string) => () => {
    const emptyIndex = mobileNumbers.findIndex((num) => !num)
    if (emptyIndex !== -1) {
      const newMobileNumbers = [...mobileNumbers]
      newMobileNumbers[emptyIndex] = mobile
      dispatch(setMobileNumbers(newMobileNumbers))
    }
  }

  const handleConfirmButtonClick = () => {
    if (mobileNumbers.every((number) => number.length === 10)) {
      // check dublicate mobile numbers
      if (mobileNumbers.length === new Set(mobileNumbers).size) {
        setSearchParams((params) => {
          params.set(URLParams.ACTIVE_STATE, "3")
          return params
        })
      } else {
        setDuplicateNumberError(true)
      }
    } else {
      const proccessedNumbers = mobileNumbers.map((number) =>
        number.length < 10 ? { isValid: false } : { isValid: true },
      )
      setErrors(errors.map((_, index) => !proccessedNumbers[index].isValid))
    }
  }

  return (
    <>
      <StepperCardHeader>
        <img src={AccountIcon} height="48px" alt="" />
        <VerifiedUserName>
          <img src={VerifiedUserIcon} height="24px" alt="" />
          <Typography>
            {userData?.firstName
              ? `${userData?.firstName} ${userData?.LastName}`
              : userData?.mobile}
          </Typography>
        </VerifiedUserName>
      </StepperCardHeader>
      <StepperCardBody>
        <Stack>
          <AddMemberText>
            Invite Team <span>(Upto {numberOfPasses})</span>
          </AddMemberText>
          <AddMemberSubText>
            Add mobile numbers of the members who will be availing the day pass
            services
          </AddMemberSubText>
        </Stack>
        <Stack gap="12px">{renderMobileNumberInputs()}</Stack>
        {showLess && <DottedDivider />}
        {userContacts && userContacts?.length !== 0 && (
          <Stack direction="row" justifyContent="center" gap="8px">
            <RestoreIcon color={showLess ? "inherit" : "primary"} />
            <UnderlineText
              className={`${showLess ? "clicked" : ""}`}
              onClick={() => setShowLess(true)}
            >
              Past Bookings
            </UnderlineText>
          </Stack>
        )}
        {showLess && (
          <>
            <Stack gap="12px">
              {userContacts?.map((contact) => (
                <HistoryItem key={contact.userId}>
                  {contact?.nickname ? contact?.nickname : contact?.mobile}
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={handleAddMobileNumber(contact.mobile)}
                    disabled={
                      mobileNumbers.every((number) => number.length > 0) ||
                      mobileNumbers.filter((num) => num === contact.mobile)
                        .length !== 0
                    }
                  >
                    Invite
                  </IconButton>
                </HistoryItem>
              ))}
            </Stack>
            <UnderlineText onClick={() => setShowLess(false)}>
              Show Less
            </UnderlineText>
          </>
        )}
        {duplicateNumberError && (
          <ErrorText>Duplicate mobile numbers found</ErrorText>
        )}
      </StepperCardBody>
      <div style={{ textAlign: "center" }}>
        <AddMemberSubText>
          Day passes are bound to the user account with unique mobile numbers &
          are non transferrable
        </AddMemberSubText>
      </div>
      <StepperCardFooter>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            setSearchParams((params) => {
              params.set(URLParams.ACTIVE_STATE, "1")
              return params
            })
          }
          sx={{ mr: 1 }}
        >
          <ArrowBackIcon />
          Back
        </Button>
        <Button variant="contained" onClick={handleConfirmButtonClick}>
          Confirm
        </Button>
      </StepperCardFooter>
    </>
  )
}

export default AddMembers
